body {
  margin: 0;
  font-family: "HurmeGeometricSans2-Black", -apple-system,  BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "HurmeGeometricSans2-Black", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "HurmeGeometricSans2-Black";
  src: url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.eot");
  src: url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/5d17319937ca2db95a75902b3c2e2fa0.svg#HurmeGeometricSans2-Black") format("svg");
}